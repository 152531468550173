#loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.container {
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu her zaman gösterir */
}