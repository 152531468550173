.hover-underline {
  text-decoration: none;
  color: #0D6EFD;
  cursor: default;
}

.hover-underline:hover {
  text-decoration: underline;
}


/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

