

.navv{
    border-width: 2px; 
    /* border-color: black; */
    border-style: solid;
    border-radius: 20px;
    padding-top: 2px;
    background-color:"#E9ECEF";
 }

 
