body {
    background-color: #E9ECEF;
}

.phone {
    display: none;
}
ul {
    list-style-type: none; 
  }

.error{

    color: red;
}

@media (max-width: 768px) {

    .container .pc {
        display: none;


    }


    .container .phone {

        display: inline;
    }

}